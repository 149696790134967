import axios from "axios";
// http://139.9.103.172:8000
const  service =axios.create({
  baseURL: 'http://139.9.103.172:8000', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
  // headers: {
  //   'Content-Type': 'application/json;charset=UTF-8'
  // }
})


export default service