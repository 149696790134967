import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [ {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue') // 假设你的登录组件是 Login.vue
  },
  {
    path: '/index', // 或者你可以设置为 '/index' 如果你想要一个专门的路径
    name: 'index',
    component: () => import('../views/index.vue') // 这是登录成功后的页面
  }]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 假设除了登录页面 '/' 之外的所有页面都需要身份验证
  if (to.path !== '/' && !localStorage.getItem('username')) {
    // 如果用户未登录且访问的不是登录页面，则重定向到登录页面
    next({
      path: '/',
      // 或者使用命名的路由 name: 'login'
    })
  } else {
    // 如果用户已登录或访问的是登录页面，则继续导航
    next()
  }
})

export default router